<template>
    <div v-if="isLoading" class="vh-100 vw-100 d-flex">
        <clip-loader  color="#012454" size="80px" class="m-auto"></clip-loader>
    </div>
    <TemplatePreview class="landing-preview" v-if="!isLoading && template && (template.status == 1 || route.name === 'LandingPreview')" :template="template" :isTemplate="false"></TemplatePreview>
    <div v-if="!isLoading && template && (template.status == 0 && route.name !== 'LandingPreview')">
        <div class="header bg-gradient-success pt-7 pb-4 pt-lg-8 pt-lg-9">
            <div class="container">
                <div class="header-body text-center mb-7">
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                    <h1 class="text-white">{{ $t("uderConstruction") }}</h1>
                    </div>
                </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                x="0"
                y="0"
                viewBox="0 0 2560 100"
                preserveAspectRatio="none"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                >
                <polygon
                    class="fill-default"
                    points="2560 0 2560 100 0 100"
                ></polygon>
                </svg>
            </div>
        </div>
        <div class="container mt--8 pb-5">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
            <div class="card card-profile bg-secondary mt-5">
                <div class="card-body px-5">
                <div class="text-center mb-4">
                    <img
                    src="/img/brand/stevie.png"
                    class="border-0 w-50"
                    alt="logo"
                    />
                </div>
                <div class="text-center">
                    <h3 class="m-0">
                    {{ $t("seeSoon") }}
                    </h3>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import TemplatePreview from '@/views/Components/TemplatePreview.vue';
import { ref, watch } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import { LandingFormClass } from '@/util/Models/LandingClass.js';
import useLandings from '../../../composables/useLandings';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import WebFontLoader from 'webfontloader';
import { onBeforeMount } from 'vue';
import { useMeta } from 'vue-meta'


export default {
    name:"CustomLanding",
    components:{
        TemplatePreview,
        ClipLoader
    },
    setup(props, {emit}){

        const route = useRoute()
        const router = useRouter()

        const { getPublicLanding }= useLandings()
        const template = ref(null)
        const isLoading = ref(true)

        const { meta }= useMeta({
            url: document.location.href,
        });

        const changeMetaInfo = (template)=>{

            const { meta_title, meta_description, meta_favicon } = template
            if( !meta_title && !meta_description && !meta_favicon ){
                return
            }
            if(meta_favicon){
                const favicon = document.getElementById("favicon");
                document.title = meta_title;
                favicon.href = meta_favicon
            }

            let description = meta_description ? meta_description : '';
            let title = meta_title ? meta_title : '';
            let favicon = meta_favicon ? meta_favicon : ''

            meta.description = description
            meta.title = title
            meta.meta = [
                { name: 'description', content: description },
                { name: 'twitter:card', content: 'summary_large_image'},
                { name: 'twitter:site', content: title },
                { name: 'twitter:title', content: title },
                { name: 'twitter:description', content: description },
                { name: 'twitter:image', content: favicon },
                { name: 'twitter:image:alt', content: title },
                { property: 'og:title', content: title },
                { property: 'og:description', content: description },
                { property: 'og:type', content: 'website' },
                { property: 'og:site_name', content: title },
                { property: 'og:url', content: document.location.href },
                { property: 'og:image', content: favicon },
            ]
        }

        const loadGoogleFonts = () => {
          let googleFonts = [template.value.title_font, template.value.subtitle_font, template.value.footer_font]
          template.value.blocks.forEach(block => {
            googleFonts.push(block.text_font, block.link_font)
          })
          googleFonts = [...new Set(googleFonts)]

          WebFontLoader.load({
            google: {
              families: googleFonts,
              // Path to stylesheet that defines font-face
              urls: ['@/assets/css/nucleo/css/nucleo.css'],
            },
            active: setFontLoaded,
          });
        }
        const setFontLoaded = () => {
          emit('font-loaded');
        }
        watch(()=> template.value, (value)=>{
            if(value && (route.name === 'CustomLanding' || route.name === 'LandingPreview')){
                changeMetaInfo(value)
            }
        })

        onBeforeMount(async()=>{
            if(route.name === "LandingPreview"){
                let templatePreview = JSON.parse(localStorage.getItem("landingPreview"));
                if(templatePreview){
                    template.value = templatePreview;
                    isLoading.value = false;
                    await loadGoogleFonts()
                    return
                }
            }

            let response = await getPublicLanding(route.params.url);

            if(response && response.status === 200 && response.data.template){
                template.value = new LandingFormClass(response.data)
            }else{
                router.push({name:"NotFound"})
            }

            isLoading.value = false;
            await loadGoogleFonts()
        })

        return {
            template,
            isLoading,
            route

        }
    }
}
</script>

<style scoped lang="scss">

</style>
